html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}

.content {
    line-height: 1.5;

    h2 {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.content-block {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;

    .screen-x-small & {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.responsive-paddings {
    padding: 20p;

    .screen-large & {
        padding: 40px;
    }
}

.dx-card.wide-card {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    border-right: 0;
    border-left: 0;
}

.full-height-scrollable > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content {
    height: 100%;

    & > .dx-scrollview-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}

$side-panel-min-width: 60px;
