$base-font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
$base-accent: #7c0103;
$base-label-color: #636d75;
$base-link-color: #7c0103;
$base-icon-color: #636d75;
$base-text-color: #333333;
$base-bg: #ffffff;
$base-border-color: #dee1e3;
$base-border-radius: 1px;
$base-success: #5cb85c;
$base-warning: #f0ad4e;
$base-danger: #f74d4d;
$base-hover-color: #333333;
$base-hover-bg: #7c01031f;
$base-focus-color: #ffffff;
$base-focus-bg: #7c0103;
$base-invalid-color: #f74d4d;
$base-invalid-faded-border-color: #f74d4d66;
$base-border-radius-small: 0px;
$base-border-radius-large: 3px;
$button-normal-color: #333333;
$button-normal-bg: #ffffff;
$button-normal-border-color: #afb6bb;
$button-default-color: #ffffff;
$button-default-bg: #7c0103;
$button-default-border-color: #630102;
$button-danger-color: #ffffff;
$button-danger-bg: #f74d4d;
$button-danger-border-color: #f63535;
$button-success-color: #ffffff;
$button-success-bg: #5cb85c;
$button-success-border-color: #4cae4c;
$list-border-color: #dee1e3;
$list-group-color: #627789;
$list-group-header-bg: #fafafa;
$list-normal-color: #333333;
$list-item-hover-bg: #7c01031f;
$list-item-selected-bg: #7c01037a;
$list-item-focused-selected-bg: #7c0103b3;
$list-focused-bg: #7c0103;
$list-item-active-bg: #7c0103;
$fieldset-field-label-color: #636d75;
$checkbox-border-color: #dee1e3;
$checkbox-focused-borderd-color: #7c0103;
$checkbox-bg: #ffffff;
$checkbox-hover-border-color: #3f0102;
$checkbox-active-icon-bg: #60606033;
$button-group-normal-selected-color: #333333;
$button-group-normal-selected-bg: #0000002b;
$button-group-default-selected-color: #7c0103;
$button-group-default-selected-bg: #7c01034d;
$button-group-danger-selected-color: #f74d4d;
$button-group-danger-selected-bg: #f74d4d4d;
$button-group-success-selected-color: #5cb85c;
$button-group-success-selected-bg: #5cb85c4d;
$scrollable-scroll-bg: #300001cc;
$scrollable-scrollbar-active-bg: #bfbfbf33;
$switch-border-color: #dee1e3;
$switch-on-color: #333333;
$switch-container-active-bg: #60606033;
$switch-off-color: #999999;
$tabs-tab-color: #333333;
$tabs-tab-selected-color: #333333;
$tabs-tab-bg: #fafafa;
$tabs-tab-selected-bg: #ffffff;
$tabs-border-color: #dee1e3;
$tabs-focused-border-color: #7c0103;
$tabs-tab-hover-bg: #7c01031f;
$badge-color: #ffffff;
$badge-bg: #7c0103;
$navbar-tab-color: #333333;
$navbar-tab-selected-color: #333333;
$navbar-tab-bg: #f7f7f7;
$navbar-tab-selected-bg: #ffffff;
$overlay-content-bg: #ffffff;
$overlay-shader-bg: #ffffffcc;
$overlay-focus-border-color: #dee1e3;
$textbox-search-icon-color: #6a757d;
$texteditor-color: #333333;
$texteditor-placeholder-color: #636d75;
$texteditor-bg: #ffffff;
$texteditor-filled-bg: #3333330d;
$texteditor-border-color: #dee1e3;
$texteditor-focused-border-color: #7c0103;
$texteditor-hover-border-color: #7c010366;
$dropdowneditor-button-hover-bg: #f5f5f5;
$dropdowneditor-button-active-bg: #b3b3b3;
$dropdowneditor-icon-color: #636d75;
$dropdowneditor-icon-active-color: #636d75;
$numberbox-spin-active-bg: #b3b3b3;
$numberbox-spin-hover-border-color: transparent;
$numberbox-spin-icon-color: #636d75;
$calendar-color: #333333;
$calendar-header-color: #627789;
$calendar-cell-other-color: #8c8c8c;
$calendar-bg: #ffffff;
$calendar-hover-bg: #7c01031f;
$calendar-cell-selected-bg: #7c0103;
$calendar-cell-active-bg: #c30205;
$calendar-border-color: #dee1e3;
$calendar-navigator-border-color: #dee1e3;
$calendar-navigator-hover-border-color: #7c0103;
$calendar-shevron-icon-color: #7c0103;
$loadindicator-bg: #7c0103;
$treeview-focused-bg: #7c0103;
$treeview-hover-bg: #7c01031f;
$treeview-border-color: #dee1e3;
$treeview-item-selected-color: #333333;
$treeview-spin-icon-color: #627789;
$menu-popup-bg: #ffffff;
$menu-item-hover-bg: #7c01031f;
$menu-color: #333333;
$menu-item-hovered-color: #333333;
$menu-item-expanded-color: #333333;
$menu-item-selected-bg: #e60206;
$menu-popup-border-color: #dee1e3;
$selectbox-list-bg: #ffffff;
$toolbar-bg: #ffffff;
$popup-title-bg: transparent;
$tileview-color: #333333;
$toast-color: #ffffff;
$toast-info-bg: #7c0103;
$toast-warning-bg: #f0ad4e;
$toast-error-bg: #f74d4d;
$toast-success-bg: #5cb85c;
$progressbar-bg: #dddddd;
$progressbar-range-bg: #7c0103;
$tooltip-color: #333333;
$tooltip-bg: #ffffff;
$tooltip-border-color: #dee1e3;
$slider-bar-bg: #dee1e3;
$slider-range-bg: #7c0103;
$gallery-indicator-bg: #ffffff;
$gallery-indicator-item-selected-bg: #7c0103;
$gallery-indicator-focused-bg: #300001;
$gallery-navbutton-hover-color: #7c01034d;
$gallery-nav-arrow-color: #ffffff;
$gallery-navbutton-active-color: #7c0103b3;
$loadpanel-content-bg: #ffffff;
$tagbox-tag-color: #333333;
$tagbox-tag-bg: #e8e8e8;
$tagbox-tag-button-remove-bg: #9ea4a8;
$radiogroup-checked-bg: #7c0103;
$radiobutton-active-bg: #60606033;
$accordion-color: #333333;
$accordion-title-color: #333333;
$accordion-item-title-opened-bg: transparent;
$accordion-item-border-color: #dee1e3;
$accordion-item-focused-border-color: #7c0103;
$accordion-title-active-color: #7c0103;
$accordion-item-hover-bg: #7c01031f;
$accordion-icon-active-color: #7c0103;
$colorbox-overlay-bg: #ffffff;
$datagrid-base-color: #333333;
$datagrid-base-background-color: #ffffff;
$datagrid-border-color: #dee1e3;
$datagrid-columnchooser-item-color: #636d75;
$datagrid-columnchooser-font-weight: normal;
$datagrid-drag-header-border-color: #7c0103b3;
$datagrid-selection-bg: #7c0103;
$datagrid-row-selected-border-color: #aa0104;
$datagrid-row-selected-color: #ffffff;
$datagrid-row-focused-color: #ffffff;
$datagrid-row-focused-bg: #963435;
$datagrid-hover-bg: #7c01031f;
$datagrid-menu-icon-color: #636d75;
$datagrid-cell-modified-border-color: #5cb85c80;
$datagrid-row-invalid-faded-border-color: #f74d4d66;
$datagrid-nodata-color: #636d75;
$datagrid-group-row-color: #636d75;
$datagrid-group-row-bg: #fafafa;
$datagrid-search-color: #ffffff;
$datagrid-spin-icon-color: #6a757d;
$datagrid-search-bg: #7c0103;
$datagrid-row-error-color: #ffffff;
$datagrid-row-error-bg: #fa9494;
$datagrid-link-color: #7c0103;
$datagrid-focused-border-color: #593334;
$datagrid-editor-bg: #ffffff;
$datagrid-columnchooser-bg: #ffffff;
$datagrid-row-alternation-bg: #fafafa;
$datagrid-summary-color: #636d75;
$pager-page-selected-color: #ffffff;
$pager-page-selected-bg: #7c0103;
$pivotgrid-area-color: #627789;
$pivotgrid-totalcolor: #f8f8f8;
$pivotgrid-grandtotalcolor: #f5f5f5;
$pivotgrid-field-area-text-color: #2a333b;
$pivotgrid-spin-icon-color: #6a757d;
$fileuploader-filename-color: #333333;
$fileuploader-falename-status-color: #999999;
$fileuploader-border-color: #dee1e3;
$scheduler-base-border-color: #dee1e399;
$scheduler-accent-border-color: #c2c8cc;
$scheduler-appointment-text-color: #ffffff;
$scheduler-appointment-base-color: #7c0103;
$scheduler-appointment-start-color: #0000004d;
$scheduler-first-month-border-color: #a7afb4;
$scheduler-workspace-focused-cell-color: #fa0206;
$scheduler-current-time-cell-color: #7c0103;
$scheduler-time-indicator-color: #c80205;
$form-field-item-color: #636d75;
$form-group-border-color: #7c0103;
$filterbuilder-text-color: #333333;
$filterbuilder-text-focus-color: #ffffff;
$filterbuilder-plus-icon-color: #5cb85c4d;
$filterbuilder-remove-icon-color: #f74d4d4d;
$filterbuilder-group-operation-color: #f74d4d4d;
$filterbuilder-item-field-color: #7c01034d;
$filterbuilder-item-operator-color: #5cb85c4d;
$filterbuilder-item-value-color: #dee1e380;
$filterbuilder-menu-icon-color: #636d75;
$drawer-shader-background-color: #00000080;
