// $font-size-base: 0.8125rem;
$font-size-base: 0.84375rem;
// $font-size-base: 0.875rem;

@import '../node_modules/bootstrap/scss/bootstrap';
@import './themes/generated/variables.cdr-scheme.scss';
@import './styles.variables.scss';

$status-new-color: #e96920;
$status-in-progress-color: #209c9c;
$status-complete-color: #3e9c42;

$status-active-color: #080;
$status-closed-color: #444;
$status-on-hold-color: #b99100;
$status-waiting-color: indianred; // coral;

// body {
//     font-size: 13px;
// }

.dx-overlay-shader {
    background-color: rgba(0, 0, 0, 0.3);
}

input,
textarea,
select,
button,
.dx-box-item-content,
.dx-widget,
.dx-widget input,
.dx-widget textarea {
    font-size: $font-size-base;
    // color: $text-color;
}

label {
    font-size: 12px;
}

header {
    .dx-toolbar .dx-toolbar-items-container {
        height: 30px;

        h2 {
            font-size: 20px;
            margin: 0;
        }
    }

    .main-menu {
        font-size: 16px;

        .dx-menu-item {
            .active {
                font-weight: 500;
            }
        }
    }
}


.line-break {
    white-space: pre-line;
}

.cursor-pointer {
    cursor: pointer;
}

.content-block {
    // margin-left: 20px;
    // margin-right: 20px;
    // margin-top: 20px;
    margin: 0;

    .screen-x-small & {
        margin: 0;
    }
}

.responsive-paddings {
    padding: 10px 10px;

    .screen-large & {
        padding: 15px 15px;
    }
}

.dx-card {
    border-radius: 0;
    margin: 0.0rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

    &:not(:first-child) {
        margin-top: 0;
    }
}

// .flex-column {
//     display: flex;
//     flex-direction: column;
// }

.main-content {
    // margin-top: 10px;
    width: 100%;
    flex: 1 0;
    display: flex;
    flex-direction: column;

    > router-outlet + * {
        flex: 1 0;
        display: flex;
        flex-direction: column;
    }
}

.dx-fileuploader-wrapper {
    padding: 3px 5px;
    background-color: lightskyblue;

    .dx-fileuploader-input-wrapper {
        padding: 3px 0;
    }
}

.toolbar {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .dx-texteditor {
        width: 150px;
    }
}

// #region dx-datagrid, dx-treelist

dx-data-grid,
dx-tree-list {
    &.flex-fill {
        > .dx-gridbase-container {
            flex: 1 0;
        }
    }
}

.dx-datagrid-rowsview.dx-empty {
    height: 25px;
}

.condensed-grid {
    .dx-datagrid {
        .dx-datagrid-content,
        .dx-datagrid-headers {
            .dx-datagrid-table {
                .dx-row {
                    > td {
                        font-size: 12px;
                        padding: 5px;

                        &:first-of-type {
                            padding-left: 7px;
                        }
                    }
                }
            }
        }
    }

    .dx-datagrid-nodata {
        font-size: 12px;
    }
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input,
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder:before {
    padding-left: 28px;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
    padding-top: 5px;
    padding-bottom: 5px;
}

.dx-datagrid .dx-editor-with-menu,
.dx-datagrid-container .dx-editor-with-menu {
    // height: 40px;
}

.dx-datagrid {
    background-color: transparent;

    .dx-toolbar {
        background-color: transparent;

        .dx-toolbar-items-container {
            height: 31px;
        }
    }

    .dx-datagrid-rowsview {
        .dx-datagrid-content {
            background-color: #fff;
        }
    }

    .dx-datagrid-headers {
        background-color: #fff;
        // color: #fff;
    }
}

.dx-datagrid,
.dx-treelist {
    // .dx-treelist-rowsview {
    // }

    .dx-treelist-header-panel, .dx-datagrid-header-panel {
        padding: 5px 10px 5px;
    }

    a {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .dx-row.dx-row-focused.dx-data-row {
        > td {
            background-color: darkgray;
            border-color: grey;
        }

        &.dx-selection {
            > td {
                // background-color: $list-item-selected-bg;
                background-color: lighten($base-accent, 20%);
            }
        }

        a {
            color: #fff !important;
        }
    }
}

.hide-headers {
    .dx-datagrid-headers {
        display: none;
    }
}

.dx-datagrid-rowsview,
.dx-treelist-rowsview {
    .dx-selection.dx-row:not(.dx-row-focused),
    .dx-selection.dx-row:not(.dx-row-focused):hover {
        > td,
        > tr > td {
            background-color: $base-accent;
            color: #fff;

            a {
                color: #fff;
            }
        }
    }
}

.dx-datagrid-search-panel,
.dx-treelist-search-panel {
    margin: 0;
}

.no-checkboxes {
    .dx-treelist-icon-container.dx-editor-inline-block {
        padding-right: 0;

        .dx-checkbox {
            display: none;
        }
    }
}

.dx-toolbar-item-content {
    .dx-textbox {
        display: inline-block;
    }
}

// #endregion dx-datagrid, dx-treelist

.dx-button {
    &.small {
        $small-button-size: 24px;
        $small-button-font-size: 16px;

        font-size: $small-button-font-size;
        width: $small-button-size;
        height: $small-button-size;
        background-position: 0px 0px;
        background-size: $small-button-size $small-button-size;
        padding: 0px;
        font-size: $small-button-font-size;
        text-align: center;
        line-height: $small-button-font-size;
        margin: 0 2px;
        vertical-align: middle;

        &.dx-button-has-icon {
            min-width: $small-button-size;
        }

        .dx-button-content {
            padding: 0;
        }

        .dx-icon {
            font-size: $small-button-font-size;
        }
    }
}

.dx-form {
    &.hide-labels {
        label {
            display: none;
        }

        .show-labels {
            label {
                display: block;
            }
        }
    }

    .dx-field-item-label-location-top {
        // padding: 8px 0 0;
    }

    label {
        margin-bottom: 2px;
    }

    .dx-layout-manager {
        .dx-field-item:not(.dx-first-col) {
            padding-left: 15px;
        }

        .dx-field-item:not(.dx-last-col) {
            padding-right: 15px;
        }

        .plain-text {
            // font-size: 16px;
            padding: 5px 0;
        }
    }

    .dx-item {
        .title {
            input {
                font-size: 1.4em;
            }
        }
    }
}

.dx-layout-manager .dx-tabpanel .dx-multiview-item-content {
    padding: 10px;
}

.dx-popup-wrapper {
    &.popup-gray {
        .dx-popup-content {
            background-color: lightgray;
        }
    }

    .dx-popup-title {
        padding: 5px 10px;
        background-color: $popup-title-bg;

        .dx-toolbar-label {
            max-width: 50% !important;
        }
    }
}

// #region dx-drawer

.dx-drawer {
    display: flex;
    flex-direction: column;
}

.dx-drawer-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
}

.dx-drawer-content,
.dx-drawer-shrink .dx-drawer-panel-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: unset;
    // height: auto;
}

// #endregion

.sidebar-right {
    .dx-drawer-panel-content {
        // margin-left: 0.5rem;
        background-color: #f0f0f0;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
}

.sidebar {
    width: 225px;
    // background-color: #f5f5f5;
    background-color: #f8f8f8;
    box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);

    .top-menu-item {
        font-size: 16px;
        padding: 3px;

        a {
            &.active {
                font-weight: bolder;
            }
        }
    }

    .dx-accordion {
        background-color: transparent;

        .dx-accordion-item {
            margin-bottom: 5px;
            border-width: 0;

            .dx-accordion-item-title {
                padding: 3px;
            }

            .dx-accordion-item-body {
                padding-bottom: 1px;
            }
        }

        .menu-item {
            font-size: 13px;
            margin-bottom: 4px;

            a {
                &.active {
                    font-weight: bolder;
                }
            }
        }

        .dx-selectbox {
            // background-color: transparent;
            // border-color: transparent;
            // border-color: #bbb;
            // color:

            // &.dx-state-focused, &.dx-state-hover {
            //     border-color: #333;
            // }
        }
    }
}

.text-bold {
    font-weight: bold;
}

.task-list-grid,
.journal-grid {
    .dx-datagrid .dx-datagrid-rowsview .dx-row > td:not(.dx-command-select),
    &.dx-treelist .dx-row > td {
        vertical-align: top;
        font-size: 12px;
    }

    .dx-treelist-header-panel, .dx-datagrid-header-panel {
        padding: 10px 10px 5px;
    }
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    // display: inline-block;
}

.dot,
.badge {
    color: #fff;
    font-weight: 600;
    padding: 2px 4px 3px;

    &.new {
        background-color: $status-new-color;
    }

    &.in-progress {
        background-color: $status-in-progress-color;
    }

    &.complete {
        background-color: $status-complete-color;
    }

    // &.active {
    //     background-color: $status-active-color;
    // }

    // &.in-progress {
    //     background-color: $status-in-progress-color;
    // }

    // &.complete {
    //     background-color: $status-complete-color;
    // }

    // &.closed {
    //     background-color: $status-closed-color;
    // }

    // &.on-hold {
    //     background-color: $status-on-hold-color;
    // }

    // &.waiting {
    //     background-color: $status-waiting-color;
    // }
}

.priority {
    &.high {
        color: $base-danger;
    }

    &.low {
        color: $base-warning;
    }
}

.task-list-grid {
    .dx-datagrid-content .dx-datagrid-table .dx-row > td {
        // padding: 5px;
    }

    .section {
        .summary {
            font-weight: 600;
            // font-size: 1em;
            // padding-left: 10px;
        }
    }

    &.feature-indent {
        .feature {
            padding-left: 10px;
        }
    }

    div.summary {
        // font-size: 1.1em;
        font-weight: 500;
        // padding-bottom: 5px;
    }

    .parent-summary {
        font-style: italic;
        font-size: 0.9em;
    }

    .status,
    .summary > a {
        &.active {
            color: $status-active-color;
        }

        &.in-progress {
            color: $status-in-progress-color;
        }

        &.complete {
            color: $status-complete-color;
            // text-decoration: line-through;
        }

        &.closed {
            color: $status-complete-color;
            text-decoration: line-through;
        }

        &.on-hold {
            color: $status-on-hold-color;
        }

        &.waiting {
            color: $status-waiting-color;
        }
    }
}

.journal-pane {
    .dx-calendar {
        width: 100%;
        min-width: 100%;
        height: 310px;
        min-height: 310px;
    }

    .journal-grid {
        // font-size: 14px;
        .dx-datagrid-content {
            background-color: transparent;
        }
        .subject {
            font-size: 12px;
            // font-weight: 600;
        }

        .duration {
            font-size: 13px;
            font-weight: 600;
        }
    }
}

.attachment-drop-zone {
    background-color: darkseagreen;
}

.attachment-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.attachment {
    position: relative;
    padding: 0.25rem;
    border: 1px solid #dee2e6;
    background-color: scale-color($button-success-bg, $lightness: 70%);
    height: 60px;

    > .image-container {
        max-width: 80px;
        overflow: hidden;

        > a {
            display: flex;
            flex-direction: row;

            &:hover {
                text-decoration: none;
            }
        }

        img.image {
            height: 50px;
        }
    }

    .name {
        font-size: 0.8em;
        overflow-wrap: break-word;
        padding-left: 5px;
        max-width: 75px;
    }

    i.icon {
        font-size: 50px;
    }

    .buttons {
        display: none;
        position: absolute;
        bottom: 2px;
        right: 2px;
        background-color: rgba(255, 255, 255, 0.7);
    }
    .delete-button {
    }

    &:hover {
        background-color: $button-success-bg;

        a {
            color: #fff;
        }

        .buttons {
            display: block;
        }
    }
}

.header-label {
    font-weight: 400;
    font-size: 30px;
    // font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    // text-transform: uppercase;
    background-color: silver;
    margin: 0.0rem;
    padding: 0.5rem;
    padding-left: 1.5rem;
}

markdown {
    ul {
        padding-left: 20px;
    }
}

.completed-dispatchNote {
    color: #989898;
}

.file-list {
    list-style-type: none;
    padding-inline-start: 0px;
    a {
        cursor: pointer;
    }
}
